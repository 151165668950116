import clsx from "clsx";
import { IconButton } from "components/Button";
import { Checkbox, CheckboxValue } from "components/Checkbox";
import * as Icon from "components/Icon";
import { PaginationBarBorder, PaginationBarProps } from "components/Pagination";
import { EditableCell, SubtextCell } from "components/Table/Cell";
import {
    CheckboxColumnFilter,
    CheckboxColumnFilterProps as CheckboxColumnFilterPropsInternal,
    ColumnFilterProps,
    ColumnFilterSummary,
    DateRangeColumnFilter,
    DateRangeColumnFilterProps as DateRangeColumnFilterPropsInternal,
    FilterRange,
    getCheckboxFilterDisplay,
    getDateRangeFilterDisplay,
    getMultiTermFilterDisplay,
    getNumberRangeFilterDisplay,
    getTextFilterDisplay,
    MultiTermColumnFilter,
    MultiTermColumnFilterProps as MultiTermColumnFilterPropsInternal,
    NumberRangeColumnFilter,
    NumberRangeColumnFilterProps as NumberRangeColumnFilterPropsInternal,
    TextColumnFilter,
    TextColumnFilterProps as TextColumnFilterPropsInternal,
    useColumnFilter,
} from "components/Table/ColumnFilter";
import {
    ActionBar,
    ActionBarProps,
    AddRowButton,
    AddRowForm,
    AddRowFormProps,
    alphanumericCaseInsensitiveSort,
    getExpandableRowIds,
    HeaderInfoIcon,
    HeaderInfoIconProps,
    RowCountSummary,
    SortIcon,
} from "components/Table/TableUtil";
import { Tooltip, TooltipProps } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import { Memo, useBrandedCallback, useBrandedMemo } from "hooks/useBranded";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useCssSelectorFilter } from "hooks/useCssSelectorFilter";
import { useDetectOverflow } from "hooks/useDetectOverflow";
import { useEventListener } from "hooks/useEventListener";
import { useFilteredEventListener } from "hooks/useFilteredEventListener";
import { useLatest } from "hooks/useLatest";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, {
    cloneElement,
    CSSProperties,
    Dispatch,
    ReactElement,
    ReactNode,
    Ref,
    RefCallback,
    SetStateAction,
    useEffect,
    useId,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import * as ReactTable from "react-table";
import * as BorderTokens from "tokens/typescript/BorderTokens";
import "./Table.scss";
import { BACKGROUND_CALLOUT } from "tokens/typescript/ColorTokens";
import { EverColor } from "tokens/typescript/EverColor";
import { getSelectedItemIndices, wrap, SEC } from "core";
import { getSizePx } from "util/css";
import { EverIdProp } from "util/type";

const SMALL_ROW_HEIGHT = 40; // matches $bb-table-small-row-height
const LARGE_ROW_HEIGHT = 56; // matches $bb-table-large-row-height
const SUB_ROW_INDENTATION = 24; // width of small icon button in px
const CELL_PADDING_HORIZONTAL = 16; // matches $bb-table-cell-horizontal-padding
const DEFAULT_ROW_CALLOUT_DURATION = 0;
const DEFAULT_ROW_CALLOUT_FADE_DURATION = 1000;

// Below are column ids reserved for special columns. Tables that use special columns
// can have rows that include an appropriate value for the associated reserved column id.

/**
 * A reserved column id for the action column. The action column (if present) is always the
 * last column and contains icon buttons for different actions that the user can take.
 *
 * Styles for spacing and alignment are built into the action column, so generally, additional
 * styling is not necessary.
 */
export const ACTION_COLUMN = "action";
/**
 * A reserved column id for the checkbox column. The checkbox column (if present) is always the
 * first column and contains a checkbox for each row, which allows the user to select the row.
 */
export const CHECKBOX_COLUMN = "checkbox";

export enum TableRowHeight {
    SMALL = "small",
    LARGE = "large",
}

export enum TableCellAlignment {
    LEFT = "left",
    CENTER = "center",
    RIGHT = "right",
}

export type TableSortFn<T extends RowObject> = ReactTable.SortByFn<T>;
export type TableDefaultSortTypes = ReactTable.DefaultSortTypes;
export type TableSortingRule<T extends RowObject> = ReactTable.SortingRule<T>;
export type TableCellRenderer<T extends RowObject> = ReactTable.Renderer<ReactTable.CellProps<T>>;

const SORT_TYPES = { alphanumeric: alphanumericCaseInsensitiveSort };

/**
 * Additional props that exist for functionality/styling specific to Bluebook tables and are
 * not recognized by react-table.
 */
type AdditionalColumnProps = {
    /**
     * An optional class name to apply to the <th> element and each <td> element in the column.
     */
    className?: string;
    /**
     * An optional class name to apply to the cell content div of each <td> element in the column.
     */
    contentClassName?: string;
    /**
     * Whether the column title should be hidden. If true, then {@link title} will be used as
     * the aria-label instead.
     */
    hideTitle?: boolean;
    /**
     * The info icon to display next to the header title.
     */
    infoIcon?: ReactElement<HeaderInfoIconProps>;
    /**
     * Whether this column should be sortable. If true, the sorting icon will be rendered
     * on the header of this column. For custom sort functions, see {@link ColumnProps.sortType}.
     */
    isSortable?: boolean;
    /**
     * Whether the cells of this column should use a smaller vertical padding (4px instead of 8px).
     * Use this option when the table uses {@link TableRowHeight.SMALL} and the column has 32px
     * tall elements inside it (e.g. text fields, small buttons, large text buttons).
     *
     * Default false.
     */
    smallVerticalPadding?: boolean;
    /**
     * The alignment of the cell content. Defaults to {@link TableCellAlignment.LEFT}.
     *
     * If the column is the {@link ACTION_COLUMN}, then defaults to
     * {@link TableCellAlignment.CENTER}.
     */
    alignContent?: TableCellAlignment;
    /**
     * Whether the cell contents in this column should be ellipsed and have tooltips
     * when the ellipsis is present. Generally this should only be used for cells with plain
     * string values.
     */
    ellipseCellContent?: boolean;
    /**
     * The filter popover button to display on the right side of the column header.
     */
    columnFilter?: ReactElement<ColumnFilterProps<unknown>>;
    /**
     * Whether the column should have a 2px right border. This is normally only applied to the
     * right-most sticky column in a table, and should never be applied to the last column.
     * For details on how to create sticky columns, see the "bb-table-sticky-column" mixin.
     */
    rightBorder?: boolean;
    /**
     * The colspan value of the <th> element of the column.
     */
    colSpan?: number;
};

export type ColumnProps<T extends RowObject> = AdditionalColumnProps & {
    /**
     * The id of the column, which must be unique.
     *
     * This will be used for both the id and accessor of the column.
     * See here for details: https://react-table-v7.tanstack.com/docs/api/useTable#column-options
     */
    id: string;
    /**
     * The column title to display in the column header.
     *
     * This prop should almost always be provided, as not providing this prop will cause the <th>
     * element to not be rendered. This is useful if the column falls under a different header
     * with a colspan > 1. If you just don't want the text to display though, then use the
     * {@link hideTitle} option instead of skipping this prop.
     */
    title?: string | number | ReactElement | readonly ReactNode[];
    /**
     * The width of the column as a string representing the CSS value (ex: "100%" for a fluid
     * width) or as the number of pixels for a fixed width. Column width should always be provided,
     * except in one specific case (see {@link TableProps.fitCellContent}).
     *
     * Note that if all columns are given a fixed pixel value, then the table will resize within
     * the bounds of the min and max width, with the column widths reflecting the ratio of the
     * provided numbers. For example, if you give the Column A a width of 50px and Column B
     * 100px, then the table may resize, but Column B will always be twice as wide as Column A.
     *
     * To achieve columns that never resize, you can set the max-width and min-width of the
     * table to the same value. If the table itself needs to resize but the columns inside
     * should have a fixed width, then ensure that the provided column widths add up to the
     * table's max-width (minus 2px for left and right borders).
     *
     * For a column whose width adjusts to fit cell content, see {@link TableProps.fitCellContent}.
     */
    width?: string | number;
    /**
     * The sort function this column should use. A custom sort function can be defined, or
     * a react-table default sort type can be used. For paginated tables, use manual sorting.
     * See {@link TableProps.sortManually} for details.
     *
     * Defaults to "alphanumeric".
     *
     * For details, see useSortBy > Column Options > sortType
     * (https://react-table-v7.tanstack.com/docs/api/useSortBy#column-options)
     */
    sortType?: Memo<TableSortFn<T>> | TableDefaultSortTypes;
    /**
     * A renderer function that receives the table instance and cell model as props and returns
     * the rendered cell value. Generally, you should just format the cell content when creating
     * the {@link RowObject}, and then you can ignore this prop.
     *
     * Occasionally, it might be useful to use this if the cell content formatting depends on
     * some property of the table instance. Another scenario where it might be useful is when
     * you want to store the raw value in the {@link RowObject} so that it can be used for
     * manual sorting or filtering.
     */
    Cell?: TableCellRenderer<T>;
};

/**
 * The column properties that are passed directly into the ReactTable.useTable hook. We include
 * properties from AdditionalColumnProps even though react-table does not recognize them so that
 * they can be used to properly display and style the cells in the corresponding column.
 */
type ReactTableColumnProps<T extends RowObject> = ReactTable.Column<T> & AdditionalColumnProps;

export type RowKey = string | number;

export interface RowObject extends Record<string, unknown> {
    /**
     * An optional class name to apply to the <tr> element.
     */
    className?: string;
    /**
     * Whether the row should be disabled. If true, disabled styles will be applied to the row.
     * Interactive elements (links, buttons, etc.) within the row must be disabled separately
     * so that they cannot be tab focused.
     */
    disabled?: boolean;
    /**
     * A list of sub-rows that are displayed when the row is expanded. When provided, an expander
     * icon will be displayed on the left side of the first cell in this row. Sub-rows can have
     * sub-rows themselves, creating a nested structure. The first cell of a sub-row will be
     * indented a fixed amount relative to its parent row.
     *
     * To expand or collapse all rows at once, use the
     * {@link TableExposedUtil.toggleAllRowsExpanded} utility method exposed through the
     * {@link BaseTableProps.exposedUtilRef} prop.
     */
    subRows?: RowObject[];
    /**
     * Whether the row can be activated. Only applicable for tables with an active state.
     * See {@link TableProps.activeRow} and {@link TableProps.setActiveRow}.
     */
    hasActiveState?: boolean;
    /**
     * The value of the selection checkbox for the row. If not provided, a checkbox will not
     * be rendered for the row.
     *
     * Only applicable for {@link AdvancedTable}s that have a checkbox column.
     */
    [CHECKBOX_COLUMN]?: CheckboxValue;
    /**
     * Whether the selection checkbox should be disabled.
     *
     * Only applicable for {@link AdvancedTable}s that have a checkbox column.
     */
    disableCheckbox?: boolean;
    /**
     * A tooltip to render on the selection checkbox.
     *
     * Only applicable for {@link AdvancedTable}s that have a checkbox column.
     */
    checkboxTooltip?: ReactElement<TooltipProps>;
    /**
     * A map representing the colspans of the row's cells, where the key is the column id
     * for the cell, and the value is the colspan that should be applied to the cell. If a cell
     * should not be rendered because it follows a cell with a colspan > 2, use the
     * {@link cellsToSkip} prop to skip rendering them.
     */
    colSpan?: Record<string, number>;
    /**
     * A list of column ids for which the corresponding cell should not be rendered. This will
     * cause the <td> element to not be rendered at all, and should only be used in conjunction
     * with the {@link colSpan} prop.
     */
    cellsToSkip?: string[];
}

export interface TableExposedUtil {
    toggleAllRowsExpanded: (expand?: boolean) => void;
}

interface BaseTableProps<T extends RowObject> extends EverIdProp {
    /**
     * An optional class name to apply to the Table component.
     */
    className?: string;
    /**
     * A list of column/header definitions.
     *
     * The provided array must have referential equality between renders, unless the value has
     * actually changed. If the array has no dependencies and never changes, then consider
     * defining it as a module level constant outside any React components. If the headers do
     * have dependencies, then memoize them with useMemo.
     */
    headers: Memo<ColumnProps<T>[]>;
    /**
     * A list of objects to display as rows in the table.
     *
     * Your table implementation should have a row object type/interface that extends
     * {@link RowObject} and includes additional fields for columns in the table, as well as
     * any other information needed to render the row.
     *
     * This prop should almost always be memoized. In the rare case that the row objects will
     * never be recalculated unnecessarily, you can skip memoization. This is the recommendation
     * of react-table, the library upon which this Table component is built.
     */
    objects: Memo<T[]>;
    /**
     * A placeholder to display when the table is empty.
     */
    placeholder: ReactNode;
    /**
     * The function to retrieve a given {@link RowObject}'s key. This could be the id or
     * display name of its associated object, or something else entirely. Each row in the table
     * must have a unique key, since that allows React to only re-render new/changed rows
     * when the table is updated.
     */
    getKey: (obj: T) => RowKey;
    /**
     * The id of another element whose text value provides an accessible name for the table.
     * For example, if the title of the table is contained in a heading element above the table,
     * use the id of that heading element.
     *
     * Either {@link aria-labelledby} or {@link aria-label} must be provided. If both are provided,
     * {@link aria-labelledby} takes precedence.
     */
    "aria-labelledby"?: string;
    /**
     * An accessible name for the table.
     *
     * Either {@link aria-labelledby} or {@link aria-label} must be provided. If both are provided,
     * {@link aria-labelledby} takes precedence.
     */
    "aria-label"?: string;
    /**
     * The id of another element whose text value describes the table. This is similar to
     * `aria-labelledby`, but whereas `aria-labelledby` should be concise, `aria-describedby` can
     * provide more verbose information.
     */
    "aria-describedby"?: string;
    /**
     * Whether the table is currently loading. If true, table rows will be displayed with a
     * shimmering animation and sorting will be disabled. Defaults to false.
     */
    isLoading?: boolean;
    /**
     * The minimum number of loading rows to display. If there are more row objects than
     * {@link minLoadingRows}, then the number of loading rows displayed will be equal to
     * the number of row objects. Defaults to 1.
     *
     * Only applicable when {@link isLoading} is true.
     */
    minLoadingRows?: number;
    /**
     * The minimum width of the table in pixels. Generally, this should be defined if it's
     * possible for table width to change depending on the width of the parent element.
     */
    minWidth?: number;
    /**
     * The maximum width of the table in pixels. Generally, this should be defined if it's
     * possible for table width to change depending on the width of the parent element.
     */
    maxWidth?: number;
    /**
     * The maximum height of the table in pixels.
     *
     * Note: When the table is paginated (see {@link AdvancedTableProps.paginationBar}), then
     * maxHeight will actually be used as the height of the table so that the table height
     * doesn't change between pages of different sizes. This means that maxHeight should be
     * set to exactly the height of the table when there are {@link PaginationBarProps.pageSize}
     * rows. This should account for all rows, the header row, and pagination bar. 2px will be
     * added automatically to account for the top and bottom border. Avoid using this option for
     * tables that have unpredictable row heights, as that can cause the table to have extra
     * space or weird scrolling behavior.
     */
    maxHeight?: number;
    /**
     * Whether the table should be styled as a full-page table. A full-page table is one that
     * covers the full vertical space of the viewport, minus any elements above it and a space
     * between the bottom of the table and the bottom of the viewport. Resizing the viewport
     * vertically may resize the table.
     *
     * Note: If this styling option is used, then {@link TableProps.maxHeight} will be ignored.
     *
     * Defaults to false.
     */
    useFullPageStyling?: boolean;
    /**
     * The minimum height of the table in pixels. Should be provided if and only if using
     * full-page table styling.
     */
    minHeight?: number;
    /**
     * The amount of space between the bottom of the table and the bottom of the viewport
     * in pixels. Should be provided if and only if using full-page table styling. Defaults to 12.
     */
    fullPageSpaceBelow?: number;
    /**
     * The default row height of the table. However, depending on the cell contents and styling,
     * a row could be taller than this default. Generally, deviations from the default row
     * height should only be allowed in one-off cases. Defaults to {@link TableRowHeight.SMALL}.
     */
    rowHeight?: TableRowHeight;
    /**
     * An object representing the current sort configuration of the table.
     *
     * Note: This may be defined even if there are no sortable columns
     * ({@link ColumnProps.isSortable} is false for all columns). In this case, the given sort
     * will be applied to the table, but the user will not be able to change the sort.
     */
    currentSort?: TableSortingRule<T>;
    /**
     * The function that is called when the sort is changed through user action. This should be
     * included if sorting is implemented manually, or some other effect needs to take place when
     * the sort changes.
     *
     * Only applicable when there are sortable columns. See {@link ColumnProps.isSortable}.
     */
    setCurrentSort?:
        | Dispatch<SetStateAction<TableSortingRule<T> | undefined>>
        | ((sort?: TableSortingRule<T>) => void);
    /**
     * If true, disables automatic sorting. {@link setCurrentSort} should be provided so that
     * the rows can be manually sorted upon detecting a change in the sort.
     * {@link ColumnProps.sortType} will be ignored.
     *
     * An example of when this would be useful is when the table is paginated, since only a
     * subset of the rows would be passed in, and automatic sorting only works on the rows
     * that are passed in.
     *
     * Only applicable when there are sortable columns. See {@link ColumnProps.isSortable}.
     *
     * Defaults to false.
     */
    sortManually?: boolean;
    /**
     * The key of the current active row. Only applicable for tables with an active row state.
     * All rows that can be activated should have {@link RowObject.hasActiveState} set, which
     * allows users to select the active row by clicking or keyboard navigation.
     */
    activeRow?: RowKey;
    /**
     * The function that is called when the user selects a new active row by clicking or
     * keyboard navigation. You may directly pass in the setter from useState, but if other
     * side effects should happen when a row is selected, or if you need access to the associated
     * click or keyboard event, then you should use a custom function.
     */
    setActiveRow?:
        | Dispatch<RowKey>
        | ((activeRow: RowKey, event?: MouseEvent | KeyboardEvent) => void);
    /**
     * A description of what happens when a row is active. This description will be displayed in
     * a tooltip when the user hovers over a selectable row.
     *
     * Only applicable for tables with an active row state.
     */
    activeStateDesc?: string | ((rowKey: RowKey) => string);
    /**
     * A state variable containing a key or array of keys corresponding to the rows that should
     * be "called out" with a background color fade-out animation. The callout rows will also
     * be scrolled into view, if applicable. Generally, the callout rows should be rows that
     * have been newly added to the table.
     *
     * {@link setCalloutRows} must be provided for this functionality to work properly.
     */
    calloutRows?: RowKey | RowKey[];
    /**
     * The setter for the {@link calloutRows} state variable.
     */
    setCalloutRows?: Dispatch<RowKey | RowKey[] | undefined>;
    /**
     * The background color of the row callout. Default {@link BACKGROUND_CALLOUT}.
     *
     * Only applicable for tables with row callout functionality.
     */
    calloutColor?: EverColor;
    /**
     * The duration of the row callout before the fade-out animation in milliseconds.
     * Default {@link DEFAULT_ROW_CALLOUT_DURATION}.
     *
     * Only applicable for tables with row callout functionality.
     */
    calloutDuration?: number;
    /**
     * The duration of the row callout fade-out animation in milliseconds.
     * Default {@link DEFAULT_ROW_CALLOUT_FADE_DURATION}.
     *
     * Only applicable for tables with row callout functionality.
     */
    calloutFadeDuration?: number;
    /**
     * A list of column ids that denotes which columns should be hidden. This array should
     * be memoized.
     */
    hiddenColumns?: string[];
    /**
     * Whether grid lines should be displayed. Defaults to false.
     */
    showGridLines?: boolean;
    /**
     * Whether the table should be striped (i.e. every other row is gray). Defaults to false.
     */
    striped?: boolean;
    /**
     * Whether the "table-layout" CSS property of the table element should be set to "auto"
     * instead of "fixed", which is what we use by default.
     * See https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout for more details.
     *
     * Using this option will make column widths adjust to fit cell content. An example of
     * where this would be useful is for a table that has potentially "infinite" levels of
     * sub-row nesting. In this case, we would want the first column to grow to fit the content
     * instead of cutting it off.
     *
     * However, if you use this option, you will not be able to specify any fixed column widths
     * using {@link ColumnProps.width}. Instead, you should set {@link ColumnProps.width} to 1
     * (or any small number that is definitely smaller than the cell content width), and
     * set the desired fixed width on the cell content div (you can use
     * {@link ColumnProps.contentClassName}). For any columns that should expand with the
     * content (e.g. the infinitely expanding column), do not set {@link ColumnProps.width}.
     *
     * Defaults to false.
     */
    fitCellContent?: boolean;
    /**
     * An optional ref which exposes utility methods as defined in {@link TableExposedUtil}.
     *
     * Only provide a ref if you need any of the exposed utility methods.
     */
    exposedUtilRef?: Ref<TableExposedUtil>;
    /**
     * Whether all expandable rows should initially be expanded.
     *
     * Only applicable for tables with expandable rows.
     */
    initialExpandAll?: boolean;
    /**
     * A callback to call when all rows have been expanded or collapsed. This function should
     * be memoized.
     *
     * Only applicable for tables with expandable rows.
     */
    onAllRowsExpandedOrCollapsed?: (allExpanded: boolean, allCollapsed: boolean) => void;
    /**
     * An action bar that will be placed directly above the table.
     */
    actionBar?: ReactElement<ActionBarProps>;
    /**
     * For use by AdvancedTable only.
     *
     * Whether the table is part of an AdvancedTable. Defaults to false.
     */
    isAdvancedTable?: boolean;
    /**
     * For use by AdvancedTable only.
     *
     * Whether a border should be displayed around the Table component. Defaults to false.
     */
    noBorder?: boolean;
}

function BaseTable<T extends RowObject>({
    everId,
    className,
    headers,
    objects,
    placeholder,
    getKey,
    isLoading = false,
    minLoadingRows = 1,
    minWidth,
    maxWidth,
    maxHeight,
    useFullPageStyling = false,
    minHeight,
    fullPageSpaceBelow = 12,
    rowHeight = TableRowHeight.SMALL,
    currentSort,
    setCurrentSort,
    sortManually = false,
    hiddenColumns,
    showGridLines = false,
    striped = false,
    activeRow,
    setActiveRow,
    activeStateDesc,
    calloutRows,
    setCalloutRows,
    calloutColor = BACKGROUND_CALLOUT,
    calloutDuration = DEFAULT_ROW_CALLOUT_DURATION,
    calloutFadeDuration = DEFAULT_ROW_CALLOUT_FADE_DURATION,
    exposedUtilRef,
    initialExpandAll,
    onAllRowsExpandedOrCollapsed,
    fitCellContent = false,
    actionBar,
    isAdvancedTable = false,
    noBorder = false,
    ...props
}: BaseTableProps<T>): ReactElement<BaseTableProps<T>> {
    if (!props["aria-labelledby"] && !props["aria-label"]) {
        console.warn(
            "Warning: either `aria-labelledby` or `aria-label` must be provided to the `Table` component",
        );
    }
    const setCurrentSortRef = useLatest(setCurrentSort);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLTableSectionElement>(null);
    // The useTable hook requires that data, columns, and initialState.sortBy all be memoized.
    const cols = useMemo<ReactTable.Column<T>[]>(() => getModifiedColumnProps(headers), [headers]);
    const initialState = useMemo<Partial<ReactTable.TableState<T>>>(() => {
        const initial: Partial<ReactTable.TableState<T>> = {};
        if (currentSort) {
            initial.sortBy = [currentSort];
        }
        if (initialExpandAll) {
            const initialExpanded: Record<RowKey, boolean> = {};
            getExpandableRowIds(objects).forEach((key) => (initialExpanded[key] = true));
            initial.expanded = initialExpanded;
        }
        return initial;
        // This only needs to run once, since it is only used for the internal initial state
        // inside the useTable hook.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        columns,
        rows,
        prepareRow,
        setSortBy,
        setHiddenColumns,
        state: { sortBy },
        toggleAllRowsExpanded,
        isAllRowsExpanded,
    } = ReactTable.useTable(
        {
            columns: cols,
            data: objects,
            initialState,
            // Override react-table's default alphanumeric sort with a case-insensitive version.
            sortTypes: SORT_TYPES,
            manualSortBy: setCurrentSortRef.current && sortManually,
            autoResetSortBy: false,
            autoResetExpanded: false,
        },
        ReactTable.useSortBy,
        ReactTable.useExpanded,
    );
    const sortByRef = useLatest(sortBy);
    const getKeyRef = useLatest(getKey);
    const setActiveRowRef = useLatest(setActiveRow);
    const [initialLoad, setInitialLoad] = useState(true);
    const initialLoadRef = useLatest(initialLoad);
    const prevIsLoadingRef = useRef<boolean>();
    const isAllRowsCollapsed =
        !isAllRowsExpanded && !rows.some((row) => row.subRows.length && row.isExpanded);
    useImperativeHandle(
        exposedUtilRef,
        () => ({
            toggleAllRowsExpanded,
        }),
        [toggleAllRowsExpanded],
    );
    useEffect(() => {
        onAllRowsExpandedOrCollapsed?.(isAllRowsExpanded, isAllRowsCollapsed);
    }, [onAllRowsExpandedOrCollapsed, isAllRowsExpanded, isAllRowsCollapsed]);
    useEffect(() => {
        if (initialLoadRef.current && prevIsLoadingRef.current === false && isLoading) {
            setInitialLoad(false);
        }
        prevIsLoadingRef.current = isLoading;
    }, [isLoading, initialLoadRef, prevIsLoadingRef]);
    useEffect(() => {
        setSortBy(currentSort ? [currentSort] : []);
    }, [currentSort, setSortBy]);
    useEffect(() => {
        setCurrentSortRef.current?.(sortBy.length ? sortBy[0] : undefined);
    }, [setCurrentSortRef, sortBy]);
    const calloutRowArray = wrap(calloutRows);
    useEffect(() => {
        if (calloutRowArray.length && setCalloutRows) {
            if (setActiveRowRef.current) {
                const newActiveRow = rows.find(
                    (row) =>
                        calloutRowArray.includes(getKeyRef.current(row.original))
                        && row.original.hasActiveState,
                );
                newActiveRow && setActiveRowRef.current(getKey(newActiveRow.original));
            }
            const timeoutId = window.setTimeout(() => {
                setCalloutRows(undefined);
            }, calloutDuration + calloutFadeDuration);
            return () => clearTimeout(timeoutId);
        }
        // Only set the active row if the calloutRows have changed. Disable exhaustive-deps
        // lint rule so that we can join calloutRows to check if any of the rows have changed.
        // Otherwise, useEffect could be unnecessarily triggered if the array does not have
        // referential equality between renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calloutRowArray.sort().join(","), setCalloutRows, rows, getKeyRef, setActiveRowRef]);
    useEffect(() => {
        // If the table is currently sorted by a hidden column and that column has a sorting icon,
        // then remove the sort. If the column doesn't have a sorting icon, don't remove the sort
        // because the change might be confusing to the user.
        if (
            sortByRef.current.length
            && hiddenColumns?.some(
                (hiddenColumn) =>
                    hiddenColumn === sortByRef.current[0].id
                    && columns.find((col) => col.id === hiddenColumn)?.canSort,
            )
        ) {
            setSortBy([]);
        }
        setHiddenColumns(hiddenColumns || []);
        // Only remove sorting on hidden columns when the columns or hidden columns change.
    }, [columns, setHiddenColumns, hiddenColumns, sortByRef, setSortBy]);
    const handleKeyDown = useBrandedCallback(
        (event: Event) => {
            const e = event as KeyboardEvent;
            if (
                (e.key !== "ArrowDown" && e.key !== "ArrowUp")
                || !wrapperRef.current
                || !e.target
                || !(e.target instanceof Element)
            ) {
                return;
            }
            e.preventDefault(); // Prevent arrow keys from scrolling table.
            !isAdvancedTable && e.stopPropagation();
            if (e.key === "ArrowDown" && e.target.closest("thead")) {
                const firstRow = wrapperRef.current.querySelector("tbody tr");
                firstRow && (firstRow as HTMLTableRowElement).focus();
                return;
            }
            const currentRow = e.target.closest("tr.bb-table__row");
            if (!currentRow) {
                return;
            }
            switch (e.key) {
                case "ArrowUp":
                    if (currentRow.previousElementSibling) {
                        (currentRow.previousElementSibling as HTMLTableRowElement).focus();
                    } else if (headerRef.current) {
                        const headerRow = headerRef.current.children[0];
                        headerRow && (headerRow as HTMLTableRowElement).focus();
                    }
                    break;
                case "ArrowDown":
                    if (currentRow.nextElementSibling) {
                        (currentRow.nextElementSibling as HTMLTableRowElement).focus();
                    }
                    break;
            }
        },
        [isAdvancedTable],
    );
    useEventListener(wrapperRef, "keydown", handleKeyDown);
    useEffect(() => {
        if (useFullPageStyling && minHeight && wrapperRef.current) {
            styleFullPageTable(wrapperRef.current, minHeight, fullPageSpaceBelow);
        }
    });
    const wrapperClasses = clsx("bb-table", className, { "bb-table--border": !noBorder });
    const rowHeightClass = `bb-table__row--${rowHeight}`;
    const expandable = objects.some((row) => row.subRows?.length);
    const allExpandable = !!objects.length && objects.every((row) => row.subRows?.length);
    const firstCalloutRow = rows.find((row) => calloutRowArray.includes(getKey(row.original)));
    const firstCalloutRowKey = firstCalloutRow ? getKey(firstCalloutRow.original) : undefined;
    const table = (
        <table
            {...getTableProps({
                className: clsx("bb-table__table", {
                    "bb-table__table--expandable": expandable,
                    "bb-table__table--auto": fitCellContent,
                    "bb-table__table--striped": striped,
                    "bb-table__table--loading": isLoading,
                }),
            })}
            aria-labelledby={props["aria-labelledby"]}
            aria-label={props["aria-label"]}
            aria-describedby={props["aria-describedby"]}
            {...everIdProp(everId)}
        >
            <thead ref={headerRef}>
                {headerGroups.map((headerGroup, i) => (
                    <HeaderRow headerGroup={headerGroup} key={i}>
                        {headerGroup.headers.map((column, j) => {
                            const toggleSort = () => {
                                if (!column.canSort) {
                                    return;
                                }
                                calloutRowArray && setCalloutRows?.(undefined);
                                column.isSorted
                                    ? column.toggleSortBy()
                                    : setSortBy([{ id: column.id }]);
                            };
                            const indent =
                                allExpandable
                                && ((j === 0 && column.id !== CHECKBOX_COLUMN)
                                    || (j === 1 && headerGroup.headers[0].id === CHECKBOX_COLUMN));
                            return (
                                <ColumnHeader
                                    key={column.id}
                                    className={clsx({
                                        "bb-table__header-cell--grid": showGridLines,
                                    })}
                                    column={column}
                                    toggleSort={toggleSort}
                                    isLoading={isLoading}
                                    indent={indent}
                                />
                            );
                        })}
                    </HeaderRow>
                ))}
            </thead>
            <tbody
                {...getTableBodyProps()}
                aria-live={!initialLoad ? "polite" : undefined}
                aria-busy={isLoading}
            >
                {isLoading
                    ? [...Array(Math.max(rows.length, minLoadingRows)).keys()].map((i) => {
                          return (
                              <LoadingRow
                                  className={clsx(rowHeightClass, {
                                      "bb-table__row--striped": striped && i % 2 === 1,
                                  })}
                                  key={i}
                                  rowKey={i}
                                  columns={headers}
                                  showGridLines={showGridLines}
                              />
                          );
                      })
                    : rows.map((row, i) => {
                          prepareRow(row);
                          const key = getKey(row.original);
                          const rowClasses = clsx(rowHeightClass, {
                              "bb-table__row--callout": calloutRowArray.includes(key),
                              "bb-table__row--striped": striped && i % 2 === 1,
                          });
                          return (
                              <TableRow
                                  className={rowClasses}
                                  key={key}
                                  rowKey={key}
                                  row={row}
                                  isActive={activeRow === key}
                                  calloutRowRef={
                                      firstCalloutRowKey && key === firstCalloutRowKey
                                          ? (row: HTMLTableRowElement | null) =>
                                                row?.scrollIntoView()
                                          : undefined
                                  }
                                  calloutColor={calloutColor}
                                  calloutDuration={calloutDuration}
                                  calloutFadeDuration={calloutFadeDuration}
                                  setActiveRow={setActiveRow}
                                  activeStateDesc={
                                      activeStateDesc
                                      && (typeof activeStateDesc === "string"
                                          ? activeStateDesc
                                          : activeStateDesc(key))
                                  }
                                  useExpandableStyling={expandable && fitCellContent}
                                  showGridLines={showGridLines}
                                  headerRowHeight={headerRef.current?.clientHeight}
                              />
                          );
                      })}
                {/* If no rows, display the placeholder. */}
                {rows.length === 0 && !isLoading && (
                    <tr className={clsx("bb-table__row", rowHeightClass)}>
                        <td
                            className={"bb-table__cell"}
                            colSpan={
                                headers.length
                                - (hiddenColumns?.filter((hiddenCol) =>
                                    headers.some((header) => header.id === hiddenCol),
                                ).length || 0)
                            }
                        >
                            <span className={"bb-table__placeholder"}>{placeholder}</span>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
    // Add extra 2px to account for top and bottom table border
    maxHeight = useFullPageStyling || !maxHeight ? undefined : maxHeight + 2;
    return actionBar ? (
        <div style={{ minWidth, maxWidth }}>
            {actionBar}
            <div ref={wrapperRef} className={wrapperClasses} style={{ maxHeight }}>
                {table}
            </div>
        </div>
    ) : (
        <div ref={wrapperRef} className={wrapperClasses} style={{ minWidth, maxWidth, maxHeight }}>
            {table}
        </div>
    );
}

/**
 * Converts the given {@link ColumnProps} to a format accepted by react-table.
 */
function getModifiedColumnProps<T extends RowObject>(
    headers: ColumnProps<T>[],
): ReactTableColumnProps<T>[] {
    return headers.map((header, index) => {
        const isActionColumn = header.id === ACTION_COLUMN;
        const col: ReactTableColumnProps<T> = {
            accessor: header.id,
            Header: header.title,
            width: header.width,
            disableSortBy: !header.isSortable,
            // The properties below don't exist on the ReactTable.column type, but are
            // included because they will still be available on the column objects returned
            // by the useTable hook.
            hideTitle: header.hideTitle,
            className: clsx(header.className, { "bb-table__cell--action": isActionColumn }),
            contentClassName: header.contentClassName,
            infoIcon: header.infoIcon,
            smallVerticalPadding: header.smallVerticalPadding,
            alignContent:
                header.alignContent || (isActionColumn && TableCellAlignment.CENTER) || undefined,
            ellipseCellContent: header.ellipseCellContent,
            columnFilter: header.columnFilter,
            rightBorder: header.rightBorder,
            colSpan: header.colSpan,
        };
        if (header.sortType) {
            col.sortType = header.sortType;
        }
        if (header.Cell) {
            col.Cell = header.Cell;
        }
        return col;
    });
}

interface HeaderRowProps<T extends RowObject> {
    children: ReactElement<ColumnHeaderProps<T>>[];
    headerGroup: ReactTable.HeaderGroup<T>;
}

function HeaderRow<T extends RowObject>({
    children,
    headerGroup,
}: HeaderRowProps<T>): ReactElement<HeaderRowProps<T>> {
    const [headerRowRef, headerRowEntry] = useResizeObserver<HTMLTableRowElement>();
    // Extra 8px acts as buffer to prevent the header from rapidly shifting between single and
    // multiline styling. If the scrollHeight > SMALL_ROW_HEIGHT, that should mean the header
    // is multiline, but if there are any display issues that bump the height up even a pixel,
    // then multiline styling would be applied if it weren't for the extra 8px allowance.
    const isMultiline =
        headerRowEntry.target && headerRowEntry.target.scrollHeight >= SMALL_ROW_HEIGHT + 8;
    return (
        <tr
            {...headerGroup.getHeaderGroupProps({
                className: clsx("bb-table__header-row", {
                    "bb-table__header-row--multiline": isMultiline,
                }),
            })}
            ref={headerRowRef}
            tabIndex={0}
        >
            {children}
        </tr>
    );
}

interface ColumnHeaderProps<T extends RowObject> {
    className: string;
    column: ReactTable.HeaderGroup<T>;
    toggleSort: () => void;
    isLoading?: boolean;
    indent?: boolean;
}

function ColumnHeader<T extends RowObject>({
    className,
    column,
    toggleSort,
    isLoading,
    indent,
}: ColumnHeaderProps<T>): ReactElement<ColumnHeaderProps<T>> {
    const [isHovered, setIsHovered] = useState(false);
    const headerContentRef = useRef(null);
    const sortIconTooltipId = "bb-table__sort-tooltip-" + useId();

    const col = column as ReactTableColumnProps<T>;
    if (!col.Header) {
        return <></>;
    }
    const headerWrapperClasses = clsx("bb-table__header-wrapper", {
        "bb-table__header-wrapper--right-aligned": col.alignContent === TableCellAlignment.RIGHT,
        "bb-table__header-wrapper--centered": col.alignContent === TableCellAlignment.CENTER,
    });
    const headerContentClasses = clsx("bb-table__header-content", {
        "bb-table__header-content--sortable": column.canSort,
        "bb-table__header-content--indented": indent,
    });
    return (
        <th
            {...column.getHeaderProps({
                className: clsx("bb-table__header-cell", className, col.className, {
                    "bb-table__header-cell--right-border": col.rightBorder,
                }),
                style: { width: column.width },
            })}
            scope={col.colSpan && col.colSpan > 1 ? "colgroup" : "col"}
            aria-label={col.hideTitle ? (col.Header as string) : undefined}
            aria-sort={
                column.isSorted ? (column.isSortedDesc ? "descending" : "ascending") : undefined
            }
            colSpan={col.colSpan}
        >
            {col.Header && !col.hideTitle && (
                <div className={headerWrapperClasses}>
                    <div className={"bb-table__header-left"}>
                        <div
                            ref={headerContentRef}
                            className={headerContentClasses}
                            role={column.canSort ? "button" : undefined}
                            aria-disabled={isLoading}
                            aria-describedby={column.canSort ? sortIconTooltipId : undefined}
                            onClick={() => !isLoading && toggleSort()}
                            onKeyDown={(e) => {
                                if (e.key === " ") {
                                    e.preventDefault(); // Prevent scrolling
                                }
                            }}
                            onKeyUp={(e) => {
                                // Using onKeyUp instead of onKeyDown so that sort isn't toggled
                                // repeatedly if the user holds the key down.
                                if (!isLoading && (e.key === "Enter" || e.key === " ")) {
                                    e.preventDefault();
                                    toggleSort();
                                }
                            }}
                            onMouseEnter={() => !isLoading && setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onFocus={() => !isLoading && setIsHovered(true)}
                            onBlur={() => setIsHovered(false)}
                            tabIndex={column.canSort && !isLoading ? 0 : undefined}
                        >
                            {col.id !== CHECKBOX_COLUMN ? (
                                <div className={"bb-table__header-text"}>
                                    {column.render("Header")}
                                </div>
                            ) : (
                                column.render("Header")
                            )}
                            {column.canSort && (
                                <div className={"bb-table__header-icon-wrapper"}>
                                    <SortIcon
                                        className={"bb-table__sort-icon"}
                                        hovered={!isLoading && isHovered}
                                        sorted={column.isSorted}
                                        sortedDesc={column.isSortedDesc}
                                    />
                                </div>
                            )}
                        </div>
                        {column.canSort && (
                            <Tooltip id={sortIconTooltipId} target={headerContentRef}>
                                {column.isSortedDesc
                                    ? "Reset sort"
                                    : column.isSorted
                                      ? "Sort descending"
                                      : "Sort ascending"}
                            </Tooltip>
                        )}
                        {col.infoIcon && (
                            <div className={"bb-table__header-icon-wrapper"}>{col.infoIcon}</div>
                        )}
                    </div>
                    {col.columnFilter && (
                        <div className={"bb-table__header-right"}>{col.columnFilter}</div>
                    )}
                </div>
            )}
        </th>
    );
}

interface TableRowProps<T extends RowObject> {
    className: string;
    rowKey: RowKey;
    row: ReactTable.Row<T>;
    isActive: boolean;
    calloutRowRef?: RefCallback<HTMLTableRowElement>;
    calloutColor: EverColor;
    calloutDuration: number;
    calloutFadeDuration: number;
    setActiveRow?:
        | Dispatch<RowKey>
        | ((activeRow: RowKey, event?: MouseEvent | KeyboardEvent) => void);
    activeStateDesc?: string;
    useExpandableStyling?: boolean;
    showGridLines?: boolean;
    headerRowHeight?: number;
}

function TableRow<T extends RowObject>({
    className,
    rowKey,
    row,
    isActive,
    calloutRowRef,
    calloutColor,
    calloutDuration,
    calloutFadeDuration,
    setActiveRow,
    activeStateDesc,
    useExpandableStyling = false,
    showGridLines,
    headerRowHeight,
}: TableRowProps<T>): ReactElement<TableRowProps<T>> {
    const handleRowSelect = useBrandedCallback(
        (event?: Event | React.KeyboardEvent) => {
            if (setActiveRow && row.original.hasActiveState && !row.original.disabled) {
                if (event instanceof MouseEvent) {
                    setActiveRow(rowKey, event);
                } else if (
                    event
                    && "nativeEvent" in event
                    && event.nativeEvent instanceof KeyboardEvent
                ) {
                    setActiveRow(rowKey, event.nativeEvent);
                } else {
                    setActiveRow(rowKey);
                }
            }
        },
        [row.original.disabled, row.original.hasActiveState, rowKey, setActiveRow],
    );
    const isSelectable = !!setActiveRow && row.original.hasActiveState && !row.original.disabled;
    const rowRef = useRef<HTMLTableRowElement>(null);
    const combinedRef = useCombinedRef(rowRef, calloutRowRef || null);
    useFilteredEventListener(rowRef, "click", handleRowSelect, useCssSelectorFilter(rowRef));
    const tooltipId = useId();
    return (
        <>
            <tr
                {...row.getRowProps({
                    className: clsx(className, row.original.className, "bb-table__row", {
                        "bb-table__row--selectable": isSelectable,
                        "bb-table__row--active": isActive,
                        "bb-table__row--disabled": row.original.disabled,
                    }),
                    style: {
                        "--bb-table-header-height": headerRowHeight + "px",
                        "--bb-tableRow-calloutDuration": calloutDuration / SEC + "s",
                        "--bb-tableRow-calloutFadeDuration": calloutFadeDuration / SEC + "s",
                        "--bb-tableRow-calloutColor": calloutColor,
                    } as CSSProperties,
                    key: rowKey,
                })}
                ref={combinedRef}
                aria-describedby={activeStateDesc && isSelectable ? tooltipId : undefined}
                aria-current={isActive || undefined}
                aria-disabled={row.original.disabled}
                onKeyUp={(e) => {
                    // Using onKeyUp instead of onKeyDown so that handleRowSelect isn't called
                    // repeatedly if the user holds down "Enter".
                    e.key === "Enter"
                        && e.target instanceof HTMLTableRowElement
                        && handleRowSelect(e);
                }}
                tabIndex={0}
            >
                {row.cells
                    .filter((cell) => !row.original.cellsToSkip?.includes(cell.column.id))
                    .map((cell, index) => (
                        <TableCell
                            className={clsx((cell.column as ReactTableColumnProps<T>).className, {
                                "bb-table__cell--grid": showGridLines,
                            })}
                            cell={cell}
                            index={index}
                            key={cell.column.id}
                            useExpandableStyling={useExpandableStyling}
                            rowTooltip={
                                // The row tooltip cannot be rendered as a child of the <tbody>
                                // or <tr>, so we render it in the first cell in the row to avoid
                                // invalid DOM structure.
                                index === 0 && activeStateDesc && isSelectable ? (
                                    <Tooltip id={tooltipId} target={rowRef}>
                                        {activeStateDesc}
                                    </Tooltip>
                                ) : undefined
                            }
                        />
                    ))}
            </tr>
        </>
    );
}

interface LoadingRowProps<T extends RowObject> {
    className: string;
    rowKey: RowKey;
    columns: ColumnProps<T>[];
    showGridLines?: boolean;
}

function LoadingRow<T extends RowObject>({
    className,
    rowKey,
    columns,
    showGridLines,
}: LoadingRowProps<T>): ReactElement<LoadingRowProps<T>> {
    return (
        <>
            <tr className={clsx(className, "bb-table__row")} key={rowKey}>
                {columns.map((column) => {
                    return (
                        <td
                            className={clsx(
                                "bb-table__cell",
                                "bb-table__cell--loading",
                                column.className,
                                {
                                    "bb-table__cell--grid": showGridLines,
                                    "bb-table__cell--right-border": column.rightBorder,
                                },
                            )}
                            style={{ width: column.width }}
                            key={column.id}
                        >
                            <div
                                className={clsx(
                                    "bb-table__loading-wrapper",
                                    "bb-table__cell-content",
                                    column.contentClassName,
                                )}
                            >
                                <div className={"bb-table__loading-shimmer"} />
                            </div>
                        </td>
                    );
                })}
            </tr>
        </>
    );
}

interface TableCellProps<T extends RowObject> {
    className: string;
    cell: ReactTable.Cell<T>;
    index: number;
    useExpandableStyling: boolean;
    rowTooltip?: ReactNode;
}

function TableCell<T extends RowObject>({
    className,
    cell,
    index,
    useExpandableStyling,
    rowTooltip,
}: TableCellProps<T>): ReactElement<TableCellProps<T>> {
    const column = cell.column as ReactTableColumnProps<T>;
    const isFirstContentCell =
        (cell.row.cells[0].column.id === CHECKBOX_COLUMN && index === 1)
        || (cell.row.cells[0].column.id !== CHECKBOX_COLUMN && index === 0);
    useExpandableStyling = useExpandableStyling && isFirstContentCell;
    const isExpanderCell = !!cell.row.subRows.length && isFirstContentCell;
    const hasIndentation = isFirstContentCell && !!cell.row.depth;
    const isNormalEllipsedCell = column.ellipseCellContent && !isExpanderCell && !hasIndentation;
    const cellContentClasses = clsx("bb-table__cell-content", column.contentClassName, {
        "bb-table__cell-content--right-aligned": column.alignContent === TableCellAlignment.RIGHT,
        "bb-table__cell-content--centered": column.alignContent === TableCellAlignment.CENTER,
        "bb-table__cell-content--flex": isExpanderCell || hasIndentation,
        "bb-ellipsis-overflow bb-focus-visible-within": isNormalEllipsedCell,
    });

    const [showTooltip, resizeRef] = useDetectOverflow<HTMLElement>({
        disabled: !column.ellipseCellContent,
    });
    const tooltipTargetRef = useRef<HTMLDivElement>(null);
    const combinedRef = useCombinedRef(resizeRef, tooltipTargetRef);

    const expanderButtonRef = useRef<HTMLButtonElement>(null);
    let expanderButton;
    if (isExpanderCell) {
        const description = cell.row.isExpanded ? "Collapse" : "Expand";
        const ExpandIcon = cell.row.isExpanded ? Icon.ChevronDown : Icon.ChevronRight;
        expanderButton = (
            <>
                <IconButton
                    ref={expanderButtonRef}
                    className={"bb-table__row-expander-button"}
                    aria-label={description}
                    onClick={() => cell.row.toggleRowExpanded()}
                >
                    <ExpandIcon size={20} />
                </IconButton>
                <Tooltip target={expanderButtonRef} aria-hidden={true}>
                    {description}
                </Tooltip>
            </>
        );
    }

    return (
        <td
            colSpan={
                cell.row.original.colSpan ? cell.row.original.colSpan[cell.column.id] : undefined
            }
            {...cell.getCellProps({
                className: clsx("bb-table__cell", className, {
                    "bb-table__cell--right-border": column.rightBorder,
                    "bb-table__cell--padding-small": column.smallVerticalPadding,
                }),
                style: { width: cell.column.width },
            })}
        >
            <div
                ref={isNormalEllipsedCell ? combinedRef : undefined}
                className={cellContentClasses}
                tabIndex={showTooltip && isNormalEllipsedCell ? 0 : undefined}
            >
                {hasIndentation && (
                    // Empty span for sub-row indentation.
                    // Extra 32px indentation for cells that don't have an expander icon so that
                    // the content lines up with cells that do.
                    <span
                        style={{
                            marginLeft: `${
                                cell.row.depth * SUB_ROW_INDENTATION + (isExpanderCell ? 0 : 32)
                            }px`,
                        }}
                    />
                )}
                {isExpanderCell ? (
                    <>
                        {expanderButton}
                        <div
                            ref={column.ellipseCellContent ? combinedRef : undefined}
                            className={clsx("bb-table__expandable-column-content", {
                                "bb-table__expandable-column-content--fit-content":
                                    useExpandableStyling,
                                "bb-ellipsis-overflow bb-focus-visible-within":
                                    column.ellipseCellContent,
                            })}
                            tabIndex={showTooltip ? 0 : undefined}
                        >
                            {cell.render("Cell")}
                        </div>
                    </>
                ) : column.ellipseCellContent && hasIndentation ? (
                    <div
                        ref={combinedRef}
                        tabIndex={showTooltip ? 0 : undefined}
                        className={"bb-ellipsis-overflow bb-focus-visible-within"}
                    >
                        {cell.render("Cell")}
                    </div>
                ) : (
                    cell.render("Cell")
                )}
            </div>
            {showTooltip && tooltipTargetRef.current && (
                <Tooltip target={tooltipTargetRef} aria-hidden={true}>
                    {cell.value}
                </Tooltip>
            )}
            {rowTooltip}
        </td>
    );
}

export type TableProps<T extends RowObject> = Omit<
    BaseTableProps<T>,
    "isAdvancedTable" | "noBorder"
>;

/**
 * A table component that includes basic functionality like single-column sorting, active rows,
 * and row expansion (sub-rows).
 *
 * To use this table, you must provide a list of column definitions {@link BaseTableProps#headers}
 * and a list of row objects {@link BaseTableProps#objects}. Each column must have a string id,
 * and for that column id within each row object, there must be a ReactNode value which will
 * populate the cell for that row and column (except in the case of an empty cell).
 *
 * Some props for cell styling like {@link ColumnProps#alignContent} and
 * {@link ColumnProps#ellipseCellContent} are provided for common use cases, but for the
 * most part, you are responsible for formatting and styling cell content.
 *
 * See TableUtil.tsx for accessory components which can be used with this Table component.
 */
export function Table<T extends RowObject>(props: TableProps<T>): ReactElement<TableProps<T>> {
    return <BaseTable {...props} />;
}

export interface CheckboxColumnProps {
    /**
     * The function to be called when one or more rows are selected.
     *
     * `newValue` is the new value that should be applied to the selected checkboxes in most
     * situations. If a single checkbox is selected, `newValue` is `CheckboxValue.TRUE` if the
     * current value is `CheckboxValue.FALSE`, otherwise it is `CheckboxValue.FALSE`.
     *
     * Multiple checkboxes can be selected by clicking one checkbox, and then Shift + clicking
     * another checkbox, which will select all the checkboxes between the two checkboxes, inclusive.
     * In this case, `newValue` takes the value of the first clicked checkbox, and generally that
     * value should be applied to all the selected checkboxes, regardless of their current values.
     *
     * If a single checkbox is selected, and `newValue` is not correct for your use-case, you can
     * use `currentValue` to determine the correct new value for the selected checkbox. If multiple
     * checkboxes are selected, then `currentValue` is not provided.
     */
    onCheckboxSelected: (
        rows: RowKey[],
        newValue: CheckboxValue,
        currentValue?: CheckboxValue,
    ) => void;
    /**
     * The function to be called when the header row checkbox is selected.
     *
     * The checkbox state of non-header rows is controlled using the
     * {@link CHECKBOX_COLUMN} field of {@link RowObject}.
     */
    onHeaderCheckboxSelected: () => void;
    /**
     * The value of the header row checkbox.
     */
    headerCheckboxValue: CheckboxValue;
    /**
     * An optional class name to apply to the <th> element and each <td> element in the
     * checkbox column.
     */
    columnClassName?: string;
}

export interface AdvancedTableProps<T extends RowObject>
    extends Omit<BaseTableProps<T>, "className" | "isAdvancedTable" | "noBorder"> {
    /**
     * An optional class name to apply to the outer wrapper, which wraps the actual Table
     * component as well as certain adjacent components, like {@link ActionBar},
     * {@link PaginationBar}, and {@link AddRowForm}.
     */
    className?: string;
    /**
     * A section for adding new rows to render directly under the {@link Table} component.
     *
     * For a toggleable section with an "Add" and "Cancel" button, use {@link AddRowForm}.
     *
     * For a section that is always displayed or has a different layout/appearance than the
     * {@link AddRowForm} component, implement your own component. Consider using a form
     * element if it makes sense.
     */
    addRowSection?: ReactElement<AddRowFormProps> | ReactNode;
    /**
     * If provided, a selection checkbox column will be added as the first column of the table.
     */
    checkboxColumnProps?: CheckboxColumnProps;
    /**
     * If provided, the table will display the pagination bar below the table (including any
     * visible "add row" sections).
     *
     * The table itself does not control any state regarding pagination. It will still display
     * all provided rows, so the correct rows need to be passed into the table based on the
     * current pagination state.
     */
    paginationBar?: ReactElement<PaginationBarProps>;
}

/**
 * A table which supports additional features, including an "add row" section, a checkbox
 * column, and a pagination bar.
 *
 * Still, more complicated features like infinite scrolling and multi-column sorting
 * are not included at this time.
 */
export function AdvancedTable<T extends RowObject>({
    fullPageSpaceBelow = 12,
    className,
    actionBar,
    addRowSection,
    checkboxColumnProps,
    paginationBar,
    headers,
    getKey,
    minWidth,
    maxWidth,
    ...props
}: AdvancedTableProps<T>): ReactElement<AdvancedTableProps<T>> {
    const tableRef = useRef<HTMLDivElement>(null);
    const [lastSelectedKey, setLastSelectedKey] = useState<RowKey | null>(null);
    useEffect(() => {
        if (props.useFullPageStyling && props.minHeight && tableRef.current) {
            styleFullPageTable(tableRef.current, props.minHeight, fullPageSpaceBelow);
        }
    });
    const handleKeyDown = useBrandedCallback(
        (event: Event) => {
            const e = event as KeyboardEvent;
            if (
                (!addRowSection && !paginationBar)
                || (e.key !== "ArrowDown" && e.key !== "ArrowUp")
                || !tableRef.current
                || !e.target
                || !(e.target instanceof Element)
            ) {
                return;
            }
            e.preventDefault(); // Prevent arrow keys from scrolling table.
            e.stopPropagation();

            // Handle special keyboard navigation cases that involve elements specific to AdvancedTable
            // like addRowSection and paginationBar, which aren't handled by BaseTable.
            const currentRow = e.target.closest("tr.bb-table__row");
            const lastRow = tableRef.current.querySelector("tbody")?.lastChild;
            const lastRowFocused = currentRow && !currentRow.nextElementSibling;
            const addRowSectionDiv = tableRef.current.querySelector(
                ".bb-advanced-table__add-row-wrapper",
            );
            const addRowSectionFocused = !!e.target.closest(".bb-advanced-table__add-row-wrapper");
            const paginationBarDiv = tableRef.current.querySelector(".bb-pagination-bar");
            const paginationBarFocused = !!e.target.closest(".bb-pagination-bar");
            if (e.key === "ArrowDown" && lastRowFocused && (addRowSectionDiv || paginationBarDiv)) {
                ((addRowSectionDiv || paginationBarDiv) as HTMLDivElement).focus();
            } else if (e.key === "ArrowDown" && addRowSectionFocused && paginationBarDiv) {
                (paginationBarDiv as HTMLDivElement).focus();
            } else if (e.key === "ArrowUp" && addRowSectionFocused) {
                (lastRow as HTMLTableRowElement).focus();
            } else if (e.key === "ArrowUp" && paginationBarFocused) {
                ((addRowSectionDiv || lastRow) as HTMLElement).focus();
            }
        },
        [addRowSection, paginationBar],
    );
    useEventListener(tableRef, "keydown", handleKeyDown);
    let spacerHeight = 0;
    const numRows = props.objects.length || 1; // If no objects, the placeholder row is displayed.
    paginationBar &&= cloneElement(paginationBar, {
        tabFocusable: true,
        borderType: PaginationBarBorder.NONE,
        "aria-label": `${props["aria-label"]} pagination footer`,
    });
    if (paginationBar && !props.isLoading) {
        if (props.maxHeight) {
            // If maxHeight is provided, then calculating the height of the spacer div
            // based on the page size and number of rows doesn't work, since that strategy
            // assumes that the table isn't scrollable.
            //
            // Instead, the given maxHeight is used as the height of table so that the
            // table doesn't change heights between pages with different row counts.
            // spacerHeight is used as the max height of the spacer div, and it grows to
            // fill up the remaining space in the table.
            spacerHeight = props.maxHeight;
        } else {
            const rowHeight = props.rowHeight === "large" ? LARGE_ROW_HEIGHT : SMALL_ROW_HEIGHT;
            spacerHeight = (paginationBar.props.pageSize - numRows) * rowHeight;
        }
    }
    headers = useBrandedMemo(
        () =>
            checkboxColumnProps
                ? [
                      {
                          id: CHECKBOX_COLUMN,
                          className: clsx(
                              "bb-table__cell--checkbox",
                              checkboxColumnProps.columnClassName,
                          ),
                          title: (
                              <Checkbox
                                  className={"bb-table__header-checkbox"}
                                  value={checkboxColumnProps.headerCheckboxValue}
                                  label={"Select all rows"}
                                  hideLabel={true}
                                  disabled={props.isLoading}
                                  onChange={() => checkboxColumnProps.onHeaderCheckboxSelected()}
                              />
                          ),
                          width: 20 + CELL_PADDING_HORIZONTAL * 2,
                          Cell: ({ row, rows }) =>
                              row.original[CHECKBOX_COLUMN] ? (
                                  <Checkbox
                                      value={row.original[CHECKBOX_COLUMN]}
                                      label={"Select row"}
                                      hideLabel={true}
                                      disabled={row.original.disableCheckbox}
                                      tooltip={row.original.checkboxTooltip}
                                      onChange={() => {}}
                                      onClick={(e) => {
                                          window.getSelection()?.removeAllRanges();
                                          if (e.shiftKey && lastSelectedKey) {
                                              e.preventDefault();
                                              const lastSelected = rows.find(
                                                  (row) => getKey(row.original) === lastSelectedKey,
                                              );
                                              if (
                                                  lastSelected
                                                  && row.index !== lastSelected.index
                                              ) {
                                                  const selectedRowKeys = getSelectedItemIndices(
                                                      row.index,
                                                      lastSelected.index,
                                                  ).map((i) => getKey(rows[i].original));
                                                  const value =
                                                      lastSelected.original[CHECKBOX_COLUMN]
                                                      || CheckboxValue.FALSE;
                                                  checkboxColumnProps.onCheckboxSelected?.(
                                                      selectedRowKeys,
                                                      value,
                                                  );
                                              }
                                          } else {
                                              const currentValue = row.original[CHECKBOX_COLUMN];
                                              const newValue =
                                                  !currentValue
                                                  || currentValue === CheckboxValue.FALSE
                                                      ? CheckboxValue.TRUE
                                                      : CheckboxValue.FALSE;
                                              checkboxColumnProps.onCheckboxSelected?.(
                                                  [getKey(row.original)],
                                                  newValue,
                                                  currentValue,
                                              );
                                          }
                                          setLastSelectedKey(getKey(row.original));
                                      }}
                                  />
                              ) : null,
                      },
                      ...headers,
                  ]
                : headers,
        [checkboxColumnProps, props.isLoading, headers, lastSelectedKey, getKey],
    );
    return (
        <div className={clsx("bb-advanced-table", className)} style={{ minWidth, maxWidth }}>
            {actionBar}
            <div
                ref={tableRef}
                className={"bb-advanced-table__table-wrapper"}
                style={
                    !props.useFullPageStyling
                        ? {
                              // If there is a pagination bar, then just use the maxHeight prop
                              // as the height. This prevents the table from changing size when
                              // switching between pages of different sizes.
                              [paginationBar ? "height" : "maxHeight"]: props.maxHeight
                                  ? // Add extra 2px to account for top and bottom table border
                                    props.maxHeight + 2
                                  : undefined,
                          }
                        : undefined
                }
            >
                <BaseTable
                    {...props}
                    headers={headers}
                    maxHeight={undefined}
                    useFullPageStyling={false}
                    isAdvancedTable={true}
                    noBorder={true}
                    minLoadingRows={paginationBar?.props.pageSize || props.minLoadingRows}
                    getKey={getKey}
                />
                {!!spacerHeight && (
                    <div
                        className={"bb-advanced-table__pagination-spacer"}
                        style={{
                            [props.maxHeight ? "maxHeight" : "height"]: spacerHeight + "px",
                        }}
                    />
                )}
                {addRowSection && (
                    <div
                        className={"bb-advanced-table__add-row-wrapper"}
                        aria-label={"Add row section"}
                        tabIndex={0}
                    >
                        {addRowSection}
                    </div>
                )}
                {paginationBar}
            </div>
        </div>
    );
}

function styleFullPageTable(element: HTMLElement, minHeight: number, spaceBelow: number): void {
    const spaceAbove = element.getBoundingClientRect().top;
    if (element.scrollHeight + 2 * getSizePx(BorderTokens.WIDTH_PRIMARY) > minHeight) {
        element.style.minHeight = minHeight + "px";
        element.style.maxHeight = `calc(100vh - ${spaceAbove + spaceBelow}px)`;
    } else {
        element.style.minHeight = "";
        element.style.maxHeight = "";
    }
}

Table.HeaderInfoIcon = HeaderInfoIcon;
Table.ColumnFilterSummary = ColumnFilterSummary;
Table.RowCountSummary = RowCountSummary;
Table.ActionBar = ActionBar;

AdvancedTable.AddRowButton = AddRowButton;
AdvancedTable.AddRowForm = AddRowForm;

// Cell content components
Table.EditableCell = EditableCell;
Table.SubtextCell = SubtextCell;

// Column filter components
export type { CheckboxColumnFilterOption } from "components/Table/ColumnFilter";
export type CheckboxColumnFilterProps = CheckboxColumnFilterPropsInternal;
Table.CheckboxColumnFilter = CheckboxColumnFilter;
Table.getCheckboxFilterDisplay = getCheckboxFilterDisplay;

export type TextColumnFilterProps = TextColumnFilterPropsInternal;
Table.TextColumnFilter = TextColumnFilter;
Table.getTextFilterDisplay = getTextFilterDisplay;

export type MultiTermColumnFilterProps = MultiTermColumnFilterPropsInternal;
Table.MultiTermColumnFilter = MultiTermColumnFilter;
Table.getMultiTermFilterDisplay = getMultiTermFilterDisplay;

export type DateFilterRange = FilterRange<Date>;
export type DateRangeColumnFilterProps = DateRangeColumnFilterPropsInternal;
Table.DateRangeColumnFilter = DateRangeColumnFilter;
Table.getDateRangeFilterDisplay = getDateRangeFilterDisplay;

export type NumberFilterRange = FilterRange<number>;
export type NumberRangeColumnFilterProps = NumberRangeColumnFilterPropsInternal;
Table.NumberRangeColumnFilter = NumberRangeColumnFilter;
Table.getNumberRangeFilterDisplay = getNumberRangeFilterDisplay;

// Hooks for accessory components
Table.useColumnFilter = useColumnFilter;
