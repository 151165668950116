import { useSetEverId } from "hooks/useSetEverId";
import React, { useCallback, useMemo, useRef, useState } from "react";
import * as ZIndexTokens from "tokens/typescript/zIndexTokens";
import {
    BaseDialogProps,
    DialogFC,
    DialogHeader,
    DialogSize,
    useDialog,
    UseDialogProps,
    UseDialogResult,
} from "./Dialog";
import { Dialog } from "primereact/dialog";
import clsx from "clsx";
import "./Dialog.scss";
import { X } from "../Icon";
import { IconButton } from "../Button";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useWindowSize, WindowSize } from "hooks/useWindowSize";
import * as CSS from "csstype";
import "primereact/resources/primereact.min.css";

const DEFAULT_MAX_HEIGHT = "80vh";

export interface NonModalProps extends BaseDialogProps {
    /**
     * Size of the dialog header.
     */
    size: Exclude<DialogSize, "FULLSCREEN"> | number;
    /**
     * An optional parameter that, when true, allows the dialog to be resized.
     * Defaults to false.
     */
    resizable?: boolean;
    /**
     * An optional parameter that, if provided, sets a minimum height for the dialog.
     */
    minHeight?: CSS.Property.MinHeight;
    /**
     * An optional parameter that, if provided, sets a minimum width for the dialog.
     */
    minWidth?: CSS.Property.MinWidth;
    /**
     * An optional parameter that, if provided, sets a maximum height for the dialog, else defaults
     * to DEFAULT_MAX_HEIGHT
     */
    maxHeight?: CSS.Property.MaxHeight;

    /**
     * The initial position for the dialog to appear in, specified in pixels.
     *
     * Position is stored in local storage, stored by the id of the dialog, so in many cases this
     * property will only affect the position of the dialog the very first time it is rendered on
     * a given device/browser.
     *
     * Note that the y property's origin is at the top of the viewport.
     */
    initialPosition?: Pick<DOMRect, "x" | "y">;
}

interface DialogPosition {
    top: number;
    left: number;
}

export type UseNonModalProps = UseDialogProps;

export interface UseNonModalResult extends Pick<UseDialogResult, "visible" | "setVisible"> {
    nonModalProps: UseDialogResult["dialogProps"];
}

function useNonModal(props: UseNonModalProps = {}): UseNonModalResult {
    const useDialogResult = useDialog(props);
    return useMemo(() => {
        const { dialogProps: nonModalProps, ...result } = useDialogResult;
        return {
            ...result,
            nonModalProps,
        };
    }, [useDialogResult]);
}

export const NonModal: DialogFC<NonModalProps, typeof useNonModal> = ({
    children,
    visible,
    id,
    everId,
    className,
    size,
    title,
    onHide,
    onShow: onShowProp,
    resizable,
    minWidth,
    minHeight,
    maxHeight = DEFAULT_MAX_HEIGHT,
    initialPosition,
}) => {
    const [storageRect, setStorageRect] = useLocalStorage<DOMRect | null>(
        `${id}.rect`,
        initialPosition ? new DOMRect(initialPosition.x, initialPosition.y) : null,
    );
    const [internalRect, setInternalRect] = useState<DOMRect | null>(null);
    const rect = id ? storageRect : internalRect;
    const setRect = id ? setStorageRect : setInternalRect;
    const [windowResizedRect, setWindowResizedRect] = useState<DialogPosition | null>(null);

    const resizeCallback = useCallback(
        (windowSize: WindowSize) => {
            if (rect) {
                let top = rect.top;
                let left = rect.left;
                if (windowSize.width && rect.right > windowSize.width) {
                    left = Math.max(windowSize.width - rect.width, 0);
                }
                if (windowSize.height && rect.bottom > windowSize.height) {
                    top = Math.max(windowSize.height - rect.height, 0);
                }
                setWindowResizedRect({ top, left });
            }
        },
        [rect],
    );

    useWindowSize(resizeCallback);

    onHide = onHide || (() => {});
    const pixelWidth = `${size}px`;

    const headerEl = <DialogHeader width={rect?.width ?? size} title={title} />;
    const dialogRef = useRef<Dialog>(null);
    const [dialogEl, setDialogEl] = useState<HTMLDivElement | null>(null);

    const onShow = useCallback(() => {
        // NonModal dialogs don't consistently trigger rerenders after the underlying elements are
        // mounted onto the DOM. Manually trigger a rerender here for any logic that requires
        // the underlying element.
        setDialogEl(dialogRef.current?.getElement() || null);
        onShowProp?.();
    }, [onShowProp]);

    const onMoveOrResize = useCallback(() => {
        const element = dialogRef?.current?.getElement();
        if (element) {
            setRect(element.getBoundingClientRect());
        }
    }, [setRect]);

    let rectStyles: React.CSSProperties = { minWidth, minHeight, maxHeight };
    if (rect) {
        rectStyles = {
            top: (windowResizedRect?.top || rect.top) + "px",
            left: (windowResizedRect?.left || rect.left) + "px",
            height: resizable ? rect.height + "px" : "auto",
            width: rect.width + "px",
            overflow: "auto",
            position: "fixed",
            ...rectStyles,
        };
    }

    useSetEverId([dialogEl, everId]);

    return (
        <Dialog
            ref={dialogRef}
            visible={visible}
            onHide={onHide}
            onShow={onShow}
            style={{ width: pixelWidth, ...rectStyles }}
            // The current base z-index value (900) is purposefully a bit smaller than the default
            // dojo dialog z-index (950). This is because backend error dialogs currently use
            // dojo dialogs, and we want those to appear over normal platform content dialogs.
            baseZIndex={ZIndexTokens.DIALOG_BASE}
            breakpoints={{ [pixelWidth]: "90vw" }}
            className={clsx("bb-component-content", "bb-dialog", className)}
            contentClassName={"bb-dialog__content bb-dialog__content--no-footer"}
            maskClassName={clsx("bb-dialog__mask", "non-modal")}
            header={headerEl}
            modal={false}
            resizable={resizable ?? false}
            maximizable={false}
            draggable={true}
            closable={true}
            icons={
                <IconButton
                    aria-label={"Close dialog"}
                    onClick={onHide}
                    className={"bb-dialog__close"}
                >
                    <X />
                </IconButton>
            }
            onResizeEnd={onMoveOrResize}
            onDragEnd={onMoveOrResize}
        >
            {children}
        </Dialog>
    );
};
NonModal.use = useNonModal;
