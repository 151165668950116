import React from "react";
import * as ReactTable from "react-table";
import "./LegacyTable.scss";

export type LegacyTableColumn<T extends Record<string, unknown>> = ReactTable.Column<T>;

export interface LegacyTableState<T extends Record<string, unknown>> {
    /*
     * A list of headers to display at the top of the table. react-table headers consist of
     * a Header (display) string, accessor string, and optional extra params that can be
     * found in react-table's UseTableColumnOptions interface.
     */
    headers: LegacyTableColumn<T>[];
    /*
     * A list of objects to display as rows in the table. In a standard table, headers and
     * objects must be based off of the same underlying type and share the same fields.
     */
    objects: T[];
    /*
     * A placeholder string to display when the table is empty.
     */
    placeholder: string | JSX.Element;
    /*
     * The function to retrieve a given object's key. This might be its id, display name,
     * or something else entirely. Each row in the table will need to have a unique key,
     * since that allows React to only re-render new/changed rows when the table is updated.
     */
    getKey: (obj: T) => string | number;
}

/**
 * Note: This table has been replaced by a new implementation, which also uses the react-table
 * library. Since this implementation is currently used in EventDateTable, we are keeping it here
 * as LegacyTable. When this table is replaced with a new component in EventDateTable, we can
 * remove this component.
 *
 * =================================================================================================
 *
 * Creates a generic, readonly table in React. The headers are sticky, and stay fixed in place
 * while scrolling through the table.
 *
 * Headers and objects must be based on a common type (NOT an interface), where each object is an
 * instance of that type and each header corresponds to one of the type's fields. The placeholder
 * string is what is displayed when the table is empty. For an example of this,
 * see EventDateTable.tsx.
 *
 * Current implementation is using the react-table library, even though other React objects are
 * using the PrimeReact library. This is because the decision to use react-table here precedes the
 * decision to use PrimeReact across the platform.
 *
 */
export const LegacyTable = <T extends Record<string, unknown>>({
    headers,
    objects,
    placeholder,
    getKey,
}: LegacyTableState<T>) => {
    const data = React.useMemo<T[]>(() => objects, [objects]);

    const columns = React.useMemo<ReactTable.Column<T>[]>(() => headers, [headers]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        ReactTable.useTable({ columns, data });

    return (
        <>
            <table {...getTableProps()} className={"bb-legacy-table"}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className={"bb-legacy-table__header"}
                                    style={{ width: column.width }}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={getKey(row.original)}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className={"bb-legacy-table__cell"}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {/* If no rows, display the placeholder string. */}
                    {rows.length === 0 && (
                        <tr>
                            <td colSpan={100} className={"bb-legacy-table__placeholder"}>
                                {placeholder}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
};
