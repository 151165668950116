import clsx from "clsx";
import { Button, ButtonProps } from "components/Button";
import { capitalize, countOf } from "core";
import { everIdProp } from "EverAttribute/EverId";
import React, {
    Dispatch,
    FormEvent,
    FormEventHandler,
    forwardRef,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useEventCallback } from "hooks/useEventCallback";
import { Complete, PromiseOr, ReplaceValues, Visited } from "core";
import { EverIdProp, FFC } from "util/type";
import { useLatest } from "hooks/useLatest";

export interface FormProps extends EverIdProp {
    /**
     * The elements to place inside the form.
     */
    children?: ReactNode;
    /**
     * An optional class name to add to the form.
     */
    className?: string;
    /**
     * An optional id to add to the form.
     */
    id?: string;
    /**
     * The callback to apply when the form is submitted.
     */
    onSubmit?: FormEventHandler<HTMLFormElement>;
}

export const Form: FFC<HTMLFormElement, FormProps> = forwardRef<HTMLFormElement, FormProps>(
    ({ children, className, id, everId, onSubmit }, ref) => {
        return (
            <form
                className={clsx("bb-form", className)}
                onSubmit={onSubmit}
                ref={ref}
                id={id}
                {...everIdProp(everId)}
            >
                {children}
            </form>
        );
    },
);
Form.displayName = "Form";

export type FormSubmitButtonProps = Omit<ButtonProps, "onClick" | "isSubmit" | "children">
    & Partial<Pick<ButtonProps, "children">>;

/**
 * A simple extension of {@link Button} which sets {@code isSubmit} to true, {@code onClick} to a
 * no-op, and {@code children} to default to "Submit".
 */
export const FormSubmitButton: FFC<HTMLButtonElement, FormSubmitButtonProps> = forwardRef(
    ({ children = "Submit", ...props }, ref) => {
        return (
            <Button {...props} children={children} isSubmit={true} onClick={() => {}} ref={ref} />
        );
    },
);
FormSubmitButton.displayName = "FormSubmitButton";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type FormFields = {};

export interface UseFormProps<T extends FormFields> {
    /**
     * The initial values to use for the form. Note that these should not be state variables, as
     * they will be stored in state by the {@link useForm} hook.
     */
    initialValues: Complete<T>;
    /**
     * The callback to apply when the form is successfully validated via
     * {@link UseFormResult.submit}. In general, a form's {@code onSubmit} callback should use
     * {@link UseFormResult.submit} rather than some other implementation, to make use of all the
     * features of the {@link useForm} hook.
     *
     * @param event the submit event generated by a form's {@code onSubmit} callback.
     */
    onSubmit: (event: FormEvent<HTMLFormElement>) => PromiseOr<void>;
    /**
     * Whether to automatically validate when {@link UseFormResult.blur} is called.
     * Defaults to true. Useful to disable when you have a long round-trip to the back-end as part
     * of your validator, in which case you would likely not want to revalidate the entire form
     * every time a field is blurred.
     */
    validateOnBlur?: boolean | ReplaceValues<T, boolean>;
    /**
     * Whether to automatically validate when {@link UseFormResult.change} is called.
     * Defaults to true. Useful to disable when you have a long round-trip to the back-end as part
     * of your validator, in which case you would likely not want to revalidate the entire form
     * every time a field is changed.
     */
    validateOnChange?: boolean | ReplaceValues<T, boolean>;
    /**
     * Whether to automatically validate when the form renders. Defaults to true.
     * Useful for disabling the "Submit" button when the form is first rendered.
     */
    validateOnLoad?: boolean | ReplaceValues<T, boolean>;
    /**
     * An object that contains validators for each field in a form. Can make use of back-end
     * validation via promises if necessary.
     */
    validator?: FormValidator<T>;
}

export type FormValueChangeCallback<T extends FormFields, K extends keyof T = keyof T> = (
    name: K,
    value: T[K],
    blur?: boolean,
) => void;

export interface UseFormResult<T extends FormFields> {
    /**
     * The current values for the form. Note that these are stored in state in the {@link useForm}
     * hook, and so it is not necessary to store in state a second time. Can be updated via
     * {@link UseFormResult.setValues} or {@link UseFormResult.change}.
     */
    values: Complete<T>;
    /**
     * A setter for the values of the form. In general, it is not necessary to use this function,
     * and you should instead use {@link UseFormResult.change} in most cases.
     */
    setValues: Dispatch<SetStateAction<Complete<T>>>;
    /**
     * An object that contains boolean values for whether the user has blurred (that is,
     * visited and navigated away from) a given field. Note that, when the user attempts to submit
     * a form, we consider all fields as having been visited, since we want to show all errors
     * (if any exist) for the current values on a failed submit, even if the user hasn't navigated
     * to that field yet.
     *
     * The values of this object can be updated via {@link UseFormResult.blur}.
     */
    blurred: Visited<T>;
    /**
     * The current errors for the form. These values are automatically updated following any call
     * to {@link UseFormResult.validate}, or {@link UseFormResult.submit}.
     *
     * In general, it is not simply enough to check for existence of a given error for a given
     * field in this value, since the user may not have visited that field yet, and we only want to
     * show errors for fields the user has actually visited.
     */
    errors: Errors<T, ReactNode>;
    /**
     * A trigger that, when called, triggers a validation of the form. Will not submit the form,
     * just updates any errors for current values of the form, according to the result of
     * {@link UseFormProps.validator}.
     *
     * Not necessary to call on blur or change for a form element unless you have disabled
     * {@link UseFormProps.validateOnBlur} or {@link UseFormProps.validateOnChange}, in which case
     * manual validation will be necessary.
     *
     * If a set of keys are provided, only those fields will be validated. Useful when you have many
     * fields with round trips to the back-end, but you only need to revalidate one or two fields.
     */
    validate: (keys?: (keyof T)[]) => void;
    /**
     * A simple callback to use for form child element's {@code onBlur} handlers, or simply a way to
     * mark a given field as having been blurred. When called, sets {@link UseFormResult.blurred}
     * to true for the given field.
     *
     * If {@link UseFormProps.validateOnBlur} is true, will automatically validate after updating
     * the blurred value for the given field with no extra work required of users of this hook.
     *
     * @param name the name of the field to mark as having been blurred
     */
    blur: (name: keyof T) => void;
    /**
     * A state value which is true iff the form is currently submitting, and therefore processing a
     * result. In general, you should use this to disable input/submit buttons in the form, though
     * this is not strictly necessary, as {@link UseFormResult.submit} will prevent the form from
     * being submitted if this value is true.
     */
    loading: boolean;
    /**
     * A function that handles the submit cycle for your form. Given the {@code onSubmit} passed to
     * {@link UseFormProps}, ensures the form is not already waiting for a result back and is valid
     * before submitting. This submit function will handle validating the form before submitting,
     * and manually validating in {@link UseFormProps.onSubmit} is not generally necessary. It is
     * typically enough to use just this as the form's {@code onSubmit} callback.
     *
     * @param event the event generated by the form's {@code onSubmit} callback
     */
    submit: (event: FormEvent<HTMLFormElement>) => void;
    /**
     * A simple callback to use for form child element's {@code onChange} handlers, or simply a way
     * to update the current value of a current property of a form. When called, updates the given
     * field's value to the given value.
     *
     * If {@link UseFormProps.validateOnChange} is true, will automatically
     * validate after updating the value with no extra work required of users of this hook.
     *
     * @param name the name of the field to update the value of
     * @param value the new value of the given field
     */
    change: FormValueChangeCallback<T, keyof T>;
    /**
     * A function that resets the state of the form. This is useful if you want to reuse the same
     * form element after a successful submit.
     */
    reset: () => void;
}

/**
 * Returns a number of state values and triggers for dealing with forms.
 *
 * See {@link UseFormResult} for more info.
 */
export function useForm<T extends FormFields>({
    initialValues,
    onSubmit,
    validator,
    validateOnBlur = true,
    validateOnChange = true,
    validateOnLoad = true,
}: UseFormProps<T>): UseFormResult<T> {
    // These arguments often don't maintain referential equality between renders, so we wrap them
    // here to avoid infinite useEffect loops.
    const initialValuesRef = useLatest(initialValues);
    const onSubmitRef = useLatest(onSubmit);
    const validatorRef = useLatest(validator);

    const [values, setValues] = useState<Complete<T>>(initialValuesRef.current);
    const [errors, setErrors] = useState<Errors<T, ReactNode>>({});
    const [validationKeys, setValidationKeys] = useState<(keyof T)[]>();

    // This checks if the value that was validated matches the current value.
    // If it does not match, we just return the existing error.
    // This function needs to be here because it needs to be wrapped in the useEventCallback
    // hook, in order to ensure the current values it checks against are up-to-date.
    // Without this hook, it could be comparing against old values from an old closure
    const filterError = useEventCallback(
        (field: keyof T, oldValues: Complete<T>, err: ReactNode | null): ReactNode | undefined => {
            if (values[field] !== oldValues[field]) {
                // if this is for a stale value, preserve the existing error instead
                return errors[field] as ReactNode;
            }
            if (err !== null) {
                return err;
            }
        },
    );

    const validateCallback = useCallback(
        (
            values: Complete<T>,
            validator: FormValidator<T> | undefined,
            validationKeys: (keyof T)[] | undefined,
        ) => {
            const result = validator
                ? validateForm(values, validator, validationKeys, filterError)
                : Promise.resolve({});
            return result.then(setErrors);
        },
        [filterError],
    );

    // Several callbacks are also wrapped with useEventCallback to ensure values are up-to-date

    const validate = useEventCallback((keys?: (keyof T)[]) => {
        keys && setValidationKeys(keys);
        validateCallback(values, validatorRef.current, validationKeys);
    });

    const [blurred, setBlurred] = useState<Visited<T>>({});
    const blur = useEventCallback((name: keyof T) => {
        setBlurred((v) => {
            const newBlurred = { ...v, [name]: true };
            if (typeof validateOnBlur === "boolean" ? validateOnBlur : validateOnBlur[name]) {
                validateCallback(values, validatorRef.current, validationKeys);
            }
            return newBlurred;
        });
    });

    const change = useEventCallback((name: keyof T, value: T[keyof T], shouldBlur = false) => {
        if (shouldBlur) {
            blur(name);
        }
        setValues((v) => {
            const newValues = { ...v, [name]: value };
            if (typeof validateOnChange === "boolean" ? validateOnChange : validateOnChange[name]) {
                validateCallback(newValues, validatorRef.current, validationKeys);
            }
            return newValues;
        });
    });

    useEffect(() => {
        validateCallback(
            values,
            validatorRef.current,
            (Object.keys(initialValuesRef.current) as (keyof T)[]).filter((k) =>
                typeof validateOnLoad === "boolean" ? validateOnLoad : validateOnLoad[k],
            ),
        );
    }, [initialValuesRef, validateCallback, validateOnLoad, validatorRef, values]);

    const [loading, setLoading] = useState(false);
    const [submitEvent, setSubmitEvent] = useState<FormEvent<HTMLFormElement>>();
    const submit = useEventCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        validateCallback(values, validatorRef.current, validationKeys).then(() => {
            const newBlurred: Visited<T> = {};
            for (const key in values) {
                newBlurred[key] = true;
            }
            setBlurred(newBlurred);
            setSubmitEvent(event);
        });
    });
    useEffect(() => {
        if (!submitEvent) {
            return;
        } else if (loading || Object.keys(errors).length) {
            setSubmitEvent(undefined);
            return;
        }
        setLoading(true);
        Promise.resolve(onSubmitRef.current?.(submitEvent)).finally(() => {
            setLoading(false);
            setSubmitEvent(undefined);
        });
    }, [errors, loading, onSubmitRef, submitEvent]);

    const reset = useCallback(() => {
        setValues(initialValuesRef.current);
        setErrors({});
        setBlurred({});
        setValidationKeys(undefined);
        setLoading(false);
        setSubmitEvent(undefined);
    }, [initialValuesRef]);

    return {
        values,
        setValues,
        blurred,
        blur,
        errors,
        validate,
        loading,
        submit,
        change,
        reset,
    };
}

/**
 * A simple utility type that, given a type T and a type R, defines an object mapping all the keys
 * of T to optional values of type R. Used by our form utilities to describe errors for a
 * given form's fields.
 */
export type Errors<T extends FormFields, R = ReactNode> = Partial<ReplaceValues<T, R>>;

/**
 * A validator for an individual field of a form. Given a set of form fields T, a value type for
 * one such form field V, and an error type for the form R, defines a function that, given a V and
 * the full values of T, returns either an R or null if no error, or a promise of an R or null if
 * no error. Used internally by form validation in {@link useForm} through {@link validateForm}
 * through {@link validateField}. See also {@link textValidator}, which sets R = string.
 */
export type FieldValidator<T extends FormFields, V, R = ReactNode> = (
    value: V | undefined,
    values?: Complete<T>,
) => PromiseOr<R | null>;

/**
 * Utility type to support form validation. For each of the properties P of form field property
 * type T, allows specification of a validator function which takes a T and returns an error of
 * type ReactNode, or null to indicate no error.
 *
 * e.g. given T = { a: string, b: number, c: { d: any }}, returns
 * { a?: (T) => ReactNode | null, b?: (T) => ReactNode | null, c?: (T) => ReactNode | null }.
 */
export type FormValidator<T extends FormFields> = {
    [K in keyof T]?: FieldValidator<T, T[K], ReactNode>;
};

/**
 * Given a set of values for a form, a set of validators for those form fields, and optionally a set
 * of keys to validate, returns a promise of errors generated by validating the given values of the
 * given keys.
 *
 * If no keys are provided, validates all values of the form.
 */
export function validateForm<T extends FormFields>(
    values: Complete<T>,
    validator: FormValidator<T>,
    keys = Object.keys(values) as (keyof T)[],
    filterError: (
        field: keyof T,
        oldValues: Complete<T>,
        error: ReactNode | null,
    ) => ReactNode | undefined,
): Promise<Errors<T, ReactNode>> {
    const result: Errors<T, ReactNode> = {};
    const promises: Promise<unknown>[] = [];
    for (const field of keys) {
        const fieldValidator = validator[field];
        if (!fieldValidator) {
            continue;
        }
        promises.push(
            validateField(field, values, fieldValidator).then((err) => {
                const filtered = filterError(field, values, err);
                if (filtered) {
                    result[field] = filtered;
                }
            }),
        );
    }
    return Promise.all(promises).then(() => result);
}

/**
 * Given a form field to validate, a set of all values from the form, and a set of validators for
 * the form, validates the given field using the given validator, returning a promise of the error,
 * or null if no error.
 */
export function validateField<T extends FormFields, P extends keyof T, R = ReactNode>(
    field: P,
    values: Complete<T>,
    validator: FieldValidator<T, T[P], R> | undefined,
): Promise<R | null> {
    return Promise.resolve(validator === undefined ? null : validator(values[field], values));
}

interface ValidatorProps<T extends FormFields> {
    required?: boolean;
    name?: string;
    /**
     * When provided with a string, will cause the validator to return null (i.e. no error)
     * when the current value is equal to the provided string, overriding any other validation
     * rules.
     *
     * When provided with a function, if the function returns true (given the current value and
     * values), will cause the validator to return null (i.e. no error), overriding any other
     * validation rules.
     */
    correctValue?:
        | ((value: string | undefined, values?: Complete<T>) => boolean)
        | string
        | undefined;
    /**
     * When provided with a string, will cause the validator to return the
     * {@code incorrectValueErrorMessage} (or "Invalid [name]" if none provided) when the value
     * is equal to the given string. Overrides all other validation rules, except for
     * {@code correctValue}.
     *
     * When provided with a function, if the function returns true (given the current value and
     * values), will cause teh validator to return {@code incorrectValueErrorMessage} (or
     * "Invalid [name]" if none provided). Overrides all other validation rules, except for
     * {@code correctValue}.
     */
    incorrectValue?:
        | ((value: string | undefined, values?: Complete<T>) => boolean)
        | string
        | undefined;
    incorrectValueErrorMessage?: string;
    /**
     * Custom error message for when a required field is empty.
     */
    requiredFieldErrorMessage?: string;
}

export interface TextValidatorProps<T extends FormFields> extends ValidatorProps<T> {
    excludeForbiddenChars?: boolean;
    forbiddenChars?: Set<string>;
    minLength?: number;
    maxLength?: number;
    trim?: boolean;
    shortenMaxLengthError?: boolean;
}

const DEFAULT_FORBIDDEN_CHARS = new Set(["/", "\\", "<", ">", "|", '"', "?", "*", ":", "%"]);

export function textValidator<T extends FormFields>({
    excludeForbiddenChars = false,
    forbiddenChars = DEFAULT_FORBIDDEN_CHARS,
    minLength = 1,
    maxLength = 255,
    required = false,
    name = "input",
    trim = true,
    shortenMaxLengthError = false,
    correctValue,
    incorrectValue,
    incorrectValueErrorMessage,
    requiredFieldErrorMessage,
}: TextValidatorProps<T> = {}): FieldValidator<T, string, string> {
    return (value, values) => {
        if (
            (correctValue !== undefined && value === correctValue)
            || (typeof correctValue === "function" && correctValue(value, values))
        ) {
            return null;
        }
        if (
            incorrectValue === value
            || (typeof incorrectValue === "function" && incorrectValue(value, values))
        ) {
            return incorrectValueErrorMessage || `Invalid ${name}`;
        }
        const maybeTrimmed = trim ? value?.trim() : value;
        const requiredMessage = requiredFieldErrorMessage ?? `${capitalize(name)} is required`;
        if (!maybeTrimmed) {
            return required ? requiredMessage : null;
        }
        if (excludeForbiddenChars && maybeTrimmed.length) {
            for (const char of maybeTrimmed) {
                if (forbiddenChars.has(char)) {
                    return "Contains invalid character";
                }
            }
        }
        const unicodeStringLength = [...maybeTrimmed].length;
        if (minLength !== undefined && unicodeStringLength < minLength) {
            return `Must be at least ${countOf(minLength, "character")}`;
        } else if (maxLength !== undefined && unicodeStringLength > maxLength) {
            return shortenMaxLengthError
                ? `Max ${countOf(maxLength, "character")}`
                : `Must be fewer than ${countOf(maxLength + 1, "character")}`;
        }
        return null;
    };
}

// This regex is largely plucked from https://www.regular-expressions.info/email.html
// Email validation is notoriously tricky. RFC 5322 and RFC 5321 (and RFCs referenced therein)
// define the world of all possible valid emails. This includes emails whose local parts (the part
// before the "@") are quoted strings, and emails whose domains (the part after the "@") are IP
// addresses. For our purposes, we will consider such email addresses as invalid.
// This regex ensures:
// 1. Overall email is 254 characters or fewer (RFC 5321 specifies 256 characters or fewer for a
// mail path, including opening and closing angle brackets, so 254 between the two)
// 2. Local part is between 1 and 64 characters (as specified by RFC 5321)
// 3. Each part of the SLD (the part before ".com", ".party", etc.) is between 1 and 63 characters
// (as specified by RFC 1123), that is, in example@X.Y.Z.com, each of X, Y, Z, etc., must be <=63
// characters.
// 4. The TLD is between 2 and 63 characters (1 character TLDs are *technically* legal, but none
// exist; fingers crossed)
// 5. The address (local part and domain) includes only valid ASCII characters; this means some
// international emails will fail this validator (e.g. addresses using domains with the .онлайн TLD;
// this can be fixed if necessary)
// 6. The local part is present, does not contain repeated "."s, does not start with a ".", and does
// not end with a "."
// 7. The domain contains an SLD and a TLD, separated by a "."
// 8. The SLD does not start with a ".", end with a ".", or contain repeated "."s.
// 9. The SLD does not start with a "-", end with a "-", or have "-"s before or after a "."
const EMAIL_REGEXP = new RegExp(
    // Positive lookahead ensures total email is between 6 and 254 characters (minimal example email
    // is "x@x.xx", 6 characters)
    "^(?=.{6,254}$)"
        // Local part
        // Positive lookahead ensures local part is between 1 and 64 characters
        + "(?=.{1,64}@)"
        // Local part, before any "."s.
        + "[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+"
        // Repeating the above part of the regex, between 0 and INF times, following a "."
        + "(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*"
        + "@"
        // SLD
        + "(?:"
        // Positive lookahead to ensure each part of the SLD (between "."s) is between 1 and 63
        // characters in length
        + "(?=.{1,63}\\.)"
        // Ensures SLD doesn't start or end with a "-", nor do any of its subdomains
        + "[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\."
        + ")+"
        // TLD
        // Positive lookahead ensures TLD is between 2 and 63 characters
        + "(?=.{2,63}$)"
        + "[a-zA-Z]+$",
);

export function emailValidator<T extends FormFields>(
    validatorProps: TextValidatorProps<T> = {},
): FieldValidator<T, string, string> {
    const localTextValidator = textValidator({
        name: "email",
        ...validatorProps,
    });
    return (value, values) => {
        const textResult = localTextValidator(value, values);
        if (textResult) {
            return textResult;
        } else if (value === "" && !validatorProps.required) {
            return null;
        } else if (value !== undefined && !EMAIL_REGEXP.test(value)) {
            return validatorProps.incorrectValueErrorMessage ?? "Not a valid email address";
        }
        return null;
    };
}

/**
 *  Adapted from {@link CustodianImportFieldDefinitions#PHONE_REGEX}}
 */
const PHONE_REGEXP = new RegExp("^[0-9,.()\\-_ +]+$");

export function phoneValidator<T extends FormFields>(
    validatorProps: TextValidatorProps<T> = {},
): FieldValidator<T, string, string> {
    const localTextValidator = textValidator({
        name: "phone",
        requiredFieldErrorMessage: "This field is required",
        ...validatorProps,
    });
    return (value, values) => {
        const textResult = localTextValidator(value, values);
        if (textResult) {
            return textResult;
        } else if (value === "" && !validatorProps.required) {
            return null;
        } else if (value !== undefined && !PHONE_REGEXP.test(value)) {
            return validatorProps.incorrectValueErrorMessage ?? "Not a valid phone number";
        }
        return null;
    };
}

export interface ValidatePasswordProps<T extends FormFields> extends TextValidatorProps<T> {
    requireUppercase?: boolean;
    requireLowercase?: boolean;
    requireNumeric?: boolean;
    requireSpecial?: boolean;
}

export function passwordValidator<T extends FormFields>({
    name = "password",
    trim = false,
    requireUppercase = false,
    requireLowercase = false,
    requireNumeric = false,
    requireSpecial = false,
    ...props
}: ValidatePasswordProps<T> = {}): FieldValidator<T, string> {
    const localTextValidator = textValidator({
        name,
        trim,
        ...props,
    });
    return (value, values) => {
        const textResult = localTextValidator(value, values);
        if (textResult) {
            return textResult;
        } else if (!value) {
            // If the value was required, text validator would have caught it. No other validation
            // necessary.
            return null;
        }
        const missingUppercase = requireUppercase && !/[A-Z]/.test(value);
        const missingLowercase = requireLowercase && !/[a-z]/.test(value);
        const missingNumeric = requireNumeric && !/[0-9]/.test(value);
        const missingSpecial =
            requireSpecial && !/[()[\]{}<>!@#$%^&*/\\?;:'",.\-_=+`~]/.test(value);
        if (!missingUppercase && !missingLowercase && !missingNumeric && !missingSpecial) {
            return null;
        }
        const phrasePrefix = `${capitalize(name)} must contain at least one `;
        return (
            <>
                {missingUppercase && `${phrasePrefix} uppercase character`}
                {missingUppercase && missingLowercase && <br />}
                {missingLowercase && `${phrasePrefix} lowercase character`}
                {(missingUppercase || missingLowercase) && missingNumeric && <br />}
                {missingNumeric && `${phrasePrefix} number`}
                {(missingUppercase || missingLowercase || missingNumeric) && missingSpecial && (
                    <br />
                )}
                {missingSpecial && `${phrasePrefix} special character`}
            </>
        );
    };
}

export function legacyPasswordValidator<T extends FormFields>(
    props: ValidatePasswordProps<T>,
): FieldValidator<T, string, string> {
    const localPasswordValidator = passwordValidator(props);
    return (value, values) => {
        const result = localPasswordValidator(value, values);
        return result === null ? result : result?.toString() || "";
    };
}

export interface NumberValidatorProps<T extends FormFields> extends ValidatorProps<T> {
    min?: number; // minimum value, inclusive
    max?: number; // maximum value, inclusive
    allowFloat?: boolean;
}

export function numberValidator<T extends FormFields>({
    name = "number",
    ...validatorProps
}: NumberValidatorProps<T> = {}): FieldValidator<T, string | number, string> {
    const localTextValidator = textValidator({
        name,
        ...validatorProps,
    });
    return (value) => {
        if (
            !validatorProps.required
            && (value === undefined || (typeof value === "string" && !value.trim()))
        ) {
            return null;
        }
        const textResult = localTextValidator(value?.toString());
        if (textResult !== null || value === undefined) {
            return textResult;
        }
        const result = +value;
        const min = validatorProps.min;
        const max = validatorProps.max;
        // taken from Is.ts, if n | 0 === n, that means n is an integer
        const isFloat = (result | 0) !== result;
        if (Number.isNaN(result)) {
            const expectedType = validatorProps.allowFloat ? "number" : "integer";
            return `Not a valid ${expectedType}`;
        } else if (!validatorProps.allowFloat && isFloat) {
            return "Must be an integer";
        } else if (min !== undefined && max !== undefined && (result < min || result > max)) {
            return `Must be between ${min} and ${max}`;
        } else if (min !== undefined && result < min) {
            return `Must be at least ${validatorProps.min}`;
        } else if (max !== undefined && result > max) {
            return `Must be at most ${max}`;
        }
        return null;
    };
}

/**
 * Note: if this regex ever changes, be sure to update the isValidHttpUrl validator in
 * Util.ts as well.
 */
const HTTP_URL_REGEXP =
    // See this stackoverflow: https://stackoverflow.com/a/5717133
    new RegExp(
        "^(https?:\\/\\/)?" // protocol
            + "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" // domain name
            + "((\\d{1,3}\\.){3}\\d{1,3}))" // OR ip (v4) address
            + "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" // port and path
            + "(\\?[;&a-z\\d%_.~+=-]*)?" // query string
            + "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i",
    );

export function httpUrlValidator<T extends FormFields>(
    validatorProps: TextValidatorProps<T> = {},
): FieldValidator<T, string, string> {
    const localTextValidator = textValidator({
        name: "url",
        ...validatorProps,
    });
    return (value, values) => {
        const textResult = localTextValidator(value, values);
        if (textResult) {
            return textResult;
        } else if (value === "" && !validatorProps.required) {
            return null;
        } else if (value !== undefined && !HTTP_URL_REGEXP.test(value)) {
            return validatorProps.incorrectValueErrorMessage ?? "Not a valid URL";
        }
        return null;
    };
}
